import type { InitiateResolutionData } from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/initiateResolution'
import type {
  RepairShop,
  RepairShopsResponse,
} from '@backmarket/http-api/src/api-specs-repair-service/types/repairShopsResponse'

export const events = {
  createRepairShopResolution: {
    type: 'CREATE_REPAIR_SHOP_RESOLUTION',
  },
  createShopRepairResolution: {
    type: 'done.invoke.createShopRepairResolution',
    data: {} as InitiateResolutionData,
  },
  createShopRepairResolutionFailed: {
    type: 'error.platform.createShopRepairResolution',
    data: {} as unknown,
  },

  getRepairShops: {
    type: 'done.invoke.getRepairShops',
    data: {} as RepairShopsResponse,
  },
  getRepairShopsFailed: {
    type: 'error.platform.getRepairShops',
    data: {} as unknown,
  },
  goBack: {
    type: 'GO_BACK',
  },
  retry: {
    type: 'RETRY',
  },
  selectRepairShop: {
    type: 'SELECT_REPAIR_SHOP',
    selectedRepairShop: null,
  } as {
    type: 'SELECT_REPAIR_SHOP'
    selectedRepairShop: null | RepairShop
  },
} as const

<template>
  <main class="contents">
    <FailLoadingScreen
      v-if="displayFailLoadingScreen"
      data-qa="resolution-flow-fail-loading-screen"
      data-test="resolution-flow-fail-loading-screen"
      @retry="onRetry"
    />

    <InvalidProblemErrorScreen
      v-if="displayInvalidLoadingProblemContextErrorScreen"
    />

    <LoadingScreen
      v-if="displayLoadingScreen"
      data-qa="resolution-flow-loading-screen"
      data-test="resolution-flow-loading-screen"
      :loading-text="i18n(translations.initialLoadingText)"
    />

    <h1 v-else>Welcome to FSM resolution flow</h1>
  </main>
</template>

<script lang="ts" setup>
import { createError, useRoute, useRuntimeConfig } from '#imports'
import { computed, onMounted, onUnmounted, shallowRef } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { interpret } from 'xstate'

import { useOrderlineDetails } from '@/scopes/care-commons/components/order/OrderlineCard/composables/useOrderlineDetails'

import FailLoadingScreen from '../common/components/FailLoadingScreen/FailLoadingScreen.vue'
import InvalidProblemErrorScreen from '../common/components/InvalidProblemErrorScreen/InvalidProblemErrorScreen.vue'
import LoadingScreen from '../common/components/LoadingScreen/LoadingScreen.vue'
import { decodeIssues } from '../common/utils/issueCommunication'

import translations from './ResolutionFlow.translations'
import { createResolutionFlowMachine } from './machine/ResolutionFlow.machine'

const i18n = useI18n()
const { params } = useRoute()
const { market } = useMarketplace()
const { FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY } =
  useRuntimeConfig().public

const {
  execute,
  data: orderline,
  error,
} = await useOrderlineDetails({
  orderlineId: params.orderlineId as string,
})

await execute()

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}

const decodedCustomerIssues = decodeIssues(params.customerIssues as string)

const resolutionFlowMachine = createResolutionFlowMachine(
  'GET_DIAGNOSIS_ACTION',
)

const isCustomerConversationEnabled = (
  FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY as string
)?.includes(market.countryCode)

const state = shallowRef(resolutionFlowMachine.initialState)

const service = interpret(
  resolutionFlowMachine.withContext({
    ...resolutionFlowMachine.context,
    diagnosisV2CustomerIssues: decodedCustomerIssues?.selectedIssues,
    diagnosisV2CustomerIssuesTags: decodedCustomerIssues?.tags,
    isCustomerConversationEnabled,
    orderline: orderline.value ?? null,
    orderlineId: orderline.value?.orderlineId ?? null,
  }),
  {
    devTools: false,
  },
).onTransition((newState) => {
  state.value = newState
})

const { send } = service

onMounted(() => {
  service.start()
})

onUnmounted(() => {
  service.stop()
})

const displayFailLoadingScreen = computed(() =>
  state.value.hasTag('loading_failed'),
)

const displayInvalidLoadingProblemContextErrorScreen = computed(() =>
  state.value.hasTag('loading_problem_context_failed'),
)

const displayLoadingScreen = computed(() => state.value.hasTag('loading'))

function onRetry() {
  send('RETRY')
}
</script>

import { initiateResolution } from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../ShopRepairFlow.machine.types'

export async function createShopRepairResolution({
  customerIssues,
  declaredWarrantyState,
  orderlineId,
  resolutionOption,
  selectedRepairShop,
}: {
  customerIssues: MachineContext['customerIssues']
  declaredWarrantyState: MachineContext['declaredWarrantyState']
  orderlineId: MachineContext['orderlineId']
  resolutionOption: MachineContext['resolutionOption']
  selectedRepairShop: MachineContext['selectedRepairShop']
}) {
  const payload = await $httpFetch(initiateResolution, {
    body: {
      declaredProblem: {
        customerIssues,
        declaredWarrantyState,
        orderlineId,
      },
      resolutionOption,
      resolutionRequest: {
        extraData: {
          shopId: selectedRepairShop?.id,
        },
      },
    },
  })

  if (!isEmpty(payload.resolution)) {
    return payload
  }

  throw new Error('Cannot create shop repair resolution')
}

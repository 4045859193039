import { getSelfTroubleshootingArticles as getSelfTroubleshootingArticlesEndpoint } from '@backmarket/http-api/src/api-specs-help-center/help-center/help-center'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export async function getSelfTroubleshootingArticles({
  diagnosisV2CustomerIssues,
  orderlineId,
}: MachineContext) {
  const payload = await $httpFetch(getSelfTroubleshootingArticlesEndpoint, {
    queryParams: {
      orderlineId,
      customerIssues: diagnosisV2CustomerIssues.join(';'),
    },
  })

  return payload
}

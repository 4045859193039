import { postShipment } from '@backmarket/http-api/src/api-specs-resolution-engine/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export async function createShipment({
  customerInput,
  diagnosisV2CustomerIssues,
  orderlineId,
  shipment,
}: MachineContext) {
  if (!isEmpty(shipment)) {
    return {
      shipment,
    }
  }

  const payload = await $httpFetch(postShipment, {
    body: {
      declaredProblem: {
        declaredWarrantyState: customerInput.declaredWarrantyState,
        orderlineId,
        customerIssues: diagnosisV2CustomerIssues,
      },
      resolutionOption: customerInput?.resolutionOptions?.[0],
    },
  })

  if (!isEmpty(payload)) {
    return payload
  }

  return Promise.reject(new Error('No shipment'))
}

import type { Address } from '../api-models'
import { createHttpEndpoint } from '../utils'

import type { RepairShopsResponse } from './types/repairShopsResponse'

export type getRepairDetailsParams = {
  repairId?: string
}

export interface getBackRepairShopsBodyParams {
  address: Address
  limit: number
}

export const getBackRepairShops = createHttpEndpoint<
  RepairShopsResponse,
  getBackRepairShopsBodyParams
>({
  method: 'POST',
  operationId: 'get-api-v1-back-repair-shops',
  path: '/bm/repair/api/v1/nearby-shops',
})

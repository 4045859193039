import { getResolutionOptions as getResolutionOptionsEndpoint } from '@backmarket/http-api/src/api-specs-resolution-engine/endpoints'
import { RESOLUTION_OPTION_TYPE_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import type { ResolutionOption } from '@backmarket/http-api/src/api-specs-resolution-engine/types/getResolutionOptions'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export async function getResolutionOptions({
  customerInput,
  diagnosisV2CustomerIssues,
  orderlineId,
}: MachineContext) {
  const { logFeatureError } = useSalesCustomerCareLogger()

  const bodyParams = {
    declaredProblem: {
      declaredWarrantyState: customerInput.declaredWarrantyState,
      orderlineId,
      customerIssues: diagnosisV2CustomerIssues,
    },
  }

  const payload = await $httpFetch(getResolutionOptionsEndpoint, {
    body: bodyParams,
  })

  if (payload.resolutionOptions && !isEmpty(payload.resolutionOptions)) {
    /*
     * Get the known resolution options from the BE
     * In case we have a mix of known and unknown resolution options we only send known resolution options to the UI and log unknow resolution options in datadog
     * In case we have only known resolution options we send them to the UI
     * In case we have only unknown resolution options we throw an error
     */

    const [knownResolutionOptions, unknowResolutionOptions] =
      payload.resolutionOptions.reduce(
        ([known, unknow], resolutionOption) => {
          return Object.values(RESOLUTION_OPTION_TYPE_ENUM).includes(
            resolutionOption.type,
          )
            ? [[...known, resolutionOption], unknow]
            : [known, [...unknow, resolutionOption]]
        },
        [[] as ResolutionOption[], [] as ResolutionOption[]],
      )

    if (isEmpty(knownResolutionOptions)) {
      logFeatureError({
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} received only unknown resolution options on get resolution option service`,
        errorDetail: {
          requestBody: bodyParams,
          unknownResolutionOptionsListReceived: unknowResolutionOptions,
        },
        featureName:
          'resolution_flow_received_only_unknown_resolution_options_on_get_resolution_options_service',
      })
      throw new Error('Only unknown resolution options')
    }

    if (!isEmpty(unknowResolutionOptions)) {
      logFeatureError({
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} received unknown resolution options on get resolution option service`,
        errorDetail: {
          requestBody: bodyParams,
          knownResolutionOptionsReceived: knownResolutionOptions,
          unknownResolutionOptionsListReceived: unknowResolutionOptions,
        },
        featureName:
          'resolution_flow_received_unknown_resolution_options_on_get_resolution_options_service',
      })
    }

    return {
      resolutionOptions: knownResolutionOptions,
    }
  }

  logFeatureError({
    errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} received no resolution options list on get resolution option service`,
    errorDetail: {
      requestBody: bodyParams,
      resolutionOptionsPayload: payload,
    },
    featureName: 'resolution_flow_received_no_resolution_options_list',
  })

  throw new Error('No resolution options list')
}

import type { MachineContext } from '../ResolutionFlow.machine.types'

/**
 * Get a unique list of all zendesk article IDs that have been loaded into
 * `selfTroubleshotingArticles`.
 *
 * @param context
 * @returns number[]
 */
export const getZendeskArticleIds = ({
  selfTroubleshootingArticles = [],
}: MachineContext) => {
  const zendeskArticleIds = new Set<number>()
  selfTroubleshootingArticles.forEach((article) => {
    article.articles.forEach((a) => zendeskArticleIds.add(a.id))
  })

  return Array.from(zendeskArticleIds)
}

/**
 * List of all customer issues in `selfTroubleshootingArticles`
 *
 * @param context
 * @returns string[]
 */
export const getSelfTroubleshootingCustomerIssues = ({
  selfTroubleshootingArticles = [],
}: MachineContext) => {
  return selfTroubleshootingArticles.map((article) => article.customerIssue)
}

import { createHttpEndpoint } from '../utils'

import type { CreateShipmentData } from './types/createShipment'
import type { GetResolutionData } from './types/getResolution'
import type { GetResolutionOptionsData } from './types/getResolutionOptions'
import type { InitiateResolutionData } from './types/initiateResolution'

/**
 * Return list of eligible resolution options given a declaredProblem
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/getResolutionOptions}
 */
export const getResolutionOptions =
  createHttpEndpoint<GetResolutionOptionsData>({
    method: 'POST',
    operationId: 'getResolutionOptions',
    path: '/resolution-engine/resolution-options',
  })

/**
 * Initiate a Resolution and returns the corresponding resolution
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/initiateResolution}
 */
export const initiateResolution = createHttpEndpoint<InitiateResolutionData>({
  method: 'POST',
  operationId: 'initiateResolution',
  path: '/resolution-engine/resolutions',
})

/**
 * Get the resolution based on a resolution_id
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/getResolutions}
 */
export const getResolution = createHttpEndpoint<GetResolutionData>({
  method: 'GET',
  operationId: 'getResolution',
  path: '/resolution-engine/resolutions/:resolutionId',
})

/**
 * Create shipment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/resolution-engine-api/definition#/web/create_shipment}
 */
export const postShipment = createHttpEndpoint<CreateShipmentData>({
  method: 'POST',
  operationId: 'createShipment',
  path: '/resolution-engine/shipments',
})

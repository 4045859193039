import { getOrderlineTimeline as getOrderlineTimelineEndpoint } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export async function getOrderlineTimeline({ orderlineId }: MachineContext) {
  const payload = await $httpFetch(getOrderlineTimelineEndpoint, {
    pathParams: {
      orderlineId,
    },
  })

  return payload
}

import { getBackRepairShops } from '@backmarket/http-api/src/api-specs-repair-service/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../ShopRepairFlow.machine.types'

export async function getRepairShops({
  resolutionOption,
}: {
  resolutionOption: MachineContext['resolutionOption']
}) {
  if (resolutionOption?.dispatchAddress) {
    const {
      dispatchAddress: { stateOrProvince, street, street2, ...rest },
    } = resolutionOption

    const payload = await $httpFetch(getBackRepairShops, {
      body: {
        address: {
          addressLine1: street,
          addressLine2: street2 ?? undefined,
          stateOrProvince: stateOrProvince ?? undefined,
          ...rest,
        },
        limit: 10,
      },
    })

    if (!isEmpty(payload.repairShops)) {
      return payload
    }
  }

  throw new Error('No shops list')
}

import { getProblem } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export async function getIssueData({
  diagnosisV2CustomerIssues,
  orderlineId,
}: MachineContext) {
  try {
    const payload = await $httpFetch(getProblem, {
      queryParams: {
        orderlineId,
        customerIssues: diagnosisV2CustomerIssues.join(';'),
      },
    })

    return {
      customerIssuesAction: payload.action,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

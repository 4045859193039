import { navigateTo } from '#imports'

import { type ServiceMap, type Typestate, assign, createMachine } from 'xstate'
import { sendParent } from 'xstate/lib/actions'

import { REPAIR as REPAIR_ROUTES } from '~/scopes/repair/routes'

import { logServiceError } from '../../../common/machine/actions/logError'
import { isInvalidProblemErrorType } from '../../../common/machine/guards/errors'

import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
} from './ShopRepairFlow.machine.types'
import { createShopRepairResolution } from './services/createShopRepairResolution'
import { getRepairShops } from './services/getRepairShops'

export function createShopRepairMachine(initialState: string | null = null) {
  return createMachine<
    MachineContext,
    MachineEvents,
    Typestate<MachineContext>,
    ServiceMap,
    MachineStateSchema
  >(
    {
      id: 'shopRepair',
      initial: initialState ?? 'SHOPS_LIST',
      preserveActionOrder: true,
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext,
        events: {} as MachineEvents,
      },
      context: {
        customerIssues: [],
        declaredWarrantyState: null,
        orderlineId: null,
        repairId: null,
        repairShops: [],
        resolutionOption: null,
        selectedRepairShop: null,
      },
      states: {
        SHOPS_LIST: {
          initial: 'LOADING',
          entry: ['clearRepairShops', 'clearSelectedRepairShop'],
          states: {
            LOADING: {
              tags: 'loading',
              invoke: {
                id: 'getRepairShops',
                src: 'getRepairShops',
                onDone: {
                  actions: 'storeRepairShops',
                  target: 'LOADED',
                },
                onError: {
                  actions: 'logErrorFromGetRepairShopsService',
                  target: 'LOADING_FAILED',
                },
              },
            },
            LOADED: {
              on: {
                SELECT_REPAIR_SHOP: {
                  actions: 'storeSelectedRepairShop',
                  target: '#CONFIRM',
                },
              },
            },
            LOADING_FAILED: {
              on: {
                RETRY: {
                  target: 'LOADING',
                },
              },
            },
          },
          on: {
            GO_BACK: {
              actions: sendParent('GO_BACK'),
            },
          },
        },
        CONFIRM: {
          id: 'CONFIRM',
          on: {
            GO_BACK: {
              target: 'SHOPS_LIST',
            },
            CREATE_REPAIR_SHOP_RESOLUTION: {
              target: 'CREATE_RESOLUTION',
            },
          },
        },
        CREATE_RESOLUTION: {
          id: 'CREATE_RESOLUTION',
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: 'loading',
              invoke: {
                id: 'createShopRepairResolution',
                src: 'createShopRepairResolution',
                onDone: {
                  actions: 'storeRepairId',
                  target: 'LOADED',
                },
                onError: [
                  {
                    actions: 'logErrorFromCreateShopRepairService',
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromCreateShopRepairService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADED: {
              entry: 'navigateToRepairTrackingPage',
              type: 'final',
            },
            LOADING_FAILED: {
              on: {
                RETRY: {
                  target: 'LOADING',
                },
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              type: 'final',
            },
          },
        },
      },
    },
    {
      actions: {
        clearRepairShops: assign(() => ({
          repairShops: [],
        })),
        clearSelectedRepairShop: assign(() => ({
          selectedRepairShop: null,
        })),
        logErrorFromCreateShopRepairService: (_context, event) => {
          logServiceError(
            '[SHOP REPAIR FLOW] error on service create shop repair',
            event,
          )
        },
        logErrorFromGetRepairShopsService: (_context, event) => {
          logServiceError(
            '[SHOP REPAIR FLOW] error on service get shops repair',
            event,
          )
        },
        navigateToRepairTrackingPage: ({ repairId }) => {
          void navigateTo({
            name: REPAIR_ROUTES.TRACKING,
            params: {
              repairId,
            },
            query: {
              withInfo: 'true',
            },
          })
        },

        storeRepairId: assign((_context, event) => ({
          repairId: event.data.resolution?.externalId,
        })),
        storeRepairShops: assign((_context, event) => ({
          repairShops: event.data.repairShops,
        })),
        storeSelectedRepairShop: assign((_context, event) => ({
          selectedRepairShop: event.selectedRepairShop,
        })),
      },
      guards: {
        isInvalidProblemErrorType: (contex, event) =>
          isInvalidProblemErrorType(contex, event),
      },
      services: { createShopRepairResolution, getRepairShops },
    },
  )
}

export const shopRepairFlowMachine = createShopRepairMachine()

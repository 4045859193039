import {
  type GetResolutionOptionsActionsEnum,
  RESOLUTION_OPTIONS_ACTIONS_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

import type { ReturnFunnelEvents } from './ResolutionFlow.machine.types'

export const KB = 1024
export const MB = 1024 * KB

/**
 * Default smartphone pictures can be heavy (e.g.: 7.5 MB),
 * And as we suggest to take 7 pictures, we have to be able to handle it.
 */
export const MAX_FILE_SIZE = 10 * MB

/**
 * The Nginx configuration has a 30 MB limit
 */
export const MAX_FILES_SIZE = 30 * MB

export const ReturnFunnelResolutionActions = [
  RESOLUTION_OPTIONS_ACTIONS_ENUM.createShipment,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showDataRemoval,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showPictures,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showPicturesWithIntro,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showMessage,
] as const

/**
 * Map between an action from the `actions` property on the resolution
 * to the corresponding event to emit. E.g. we should go to the
 * data removal screen for the data removal action.
 */
export const ResolutionActionToEvent: Record<
  GetResolutionOptionsActionsEnum,
  ReturnFunnelEvents['type']
> = {
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.createShipment]: 'GO_TO_SHIPMENT',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showDataRemoval]: 'GO_TO_DATA_REMOVAL',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showPictures]: 'GO_TO_PICTURES',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showPicturesWithIntro]:
    'GO_TO_PICTURES_INTRO',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showMessage]: 'GO_TO_DESCRIPTION',
}
